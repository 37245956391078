<template>
  <div class="animated fadeIn">
    <h3>Рейтинг</h3>
    <b-card
      v-for="index in indexes"
      :key="index.id"
      no-body
    >
      <b-card-header v-b-toggle="'index-'+index.id">
        <h4>{{ index.name }}</h4>
      </b-card-header>
      <b-collapse
        :id="'index-' + index.id"
        class="card-body"
      >
        <b-tabs
          justified
          tabs
        >
          <b-tab
            v-for="periodIndex in index.ratingPeriodIndexes"
            :key="periodIndex.id"
            :title="periodIndex.ratingPeriod.name"
          >
            <optional-rating-setting
              v-if="index.type === 'optional'"
              :index="periodIndex"
            />

            <lateness-rating-setting
              v-else-if="index.type === 'lateness'"
              :index="index"
            />
            <simple-rating-setting
              v-else-if="index.type === 'simple'"
              :index="periodIndex"
            />
            <b-row>
              <b-col>
                <b-form-group label="Название">
                  <b-form-input
                    v-model="index.name"
                    autocomplete="false"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col>
                <b-form-group
                  label="Период расчета"
                  class="form-group-el-select"
                >
                  <el-select
                    v-model="index.periodStart"
                    class="form-control"
                    name="period"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in periods"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    />
                  </el-select>
                </b-form-group>
              </b-col> -->
              <b-col>
                <b-form-group label="Коэффициент удельного веса">
                  <b-form-input
                    v-model="periodIndex.percentKoeff"
                    autocomplete="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              variant="primary"
              @click="updateIndex(index)"
            >
              Сохранить
            </b-button>
            <b-card class="mt-3">
              <div class="history-rating">
                <h6 class="history-rating__title">
                  События
                </h6>
                <p class="history-rating__desc">
                  Список событий сформированный на протяжении всего существования настройки
                </p>
                <ul
                  v-if="periodIndex.settingHistories.length != 0"
                  class="history-rating__list"
                >
                  <li
                    v-for="historyItem in periodIndex.settingHistories"
                    :key="'history-item-' + historyItem.id"
                    class="history-rating__list-item"
                  >
                    <span
                      class="text-success"
                    ><span class="history-rating__list-item-date">{{ formatDatetime(historyItem.createdOn) }}</span>
                      <span v-if="historyItem.type == 'index_koeff'">
                        Обновлен коэфициент
                      </span>
                      <span v-else-if="historyItem.type == 'option_value'">
                        Обновлена опция
                      </span>
                      <span v-else-if="historyItem.type == 'index_update'">
                        Значение обновлено
                      </span>
                      <span v-else-if="historyItem.type == 'option_create'">
                        Добавлена опция
                      </span>
                      <span v-else-if="historyItem.type == 'option_delete'">
                        Удалена опция
                      </span>
                    </span>
                  </li>
                </ul>
                <ul
                  v-else
                  class="history-rating__list"
                >
                  <li class="history-rating__list-item">
                    <span class="text-warning">История изменений отсутствует</span>
                  </li>
                </ul>
              </div>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-collapse>
    </b-card>
    <b-card
      v-if="history[0]"
      no-body
    >
      <b-card-header v-b-toggle="'globalRatingReload'">
        <h4>Глобальный расчет рейтинга</h4>
      </b-card-header>
      <b-collapse
        id="globalRatingReload"
        class="card-body"
      >
        <h5>
          Период общего перерасчета рейтинга
        </h5>
        <p><span>Дата последнего расчета:</span> <b>{{ formatDatetime(history[0].createdOn) }}</b></p>
        <p>
          <span>За период:
          </span> <b>{{ formatDate(history[0].rating.dateFrom) }} - {{ formatDate(history[0].rating.dateTo) }}</b>
        </p>
        <b-button
          variant="success"
          @click="recountAll"
        >
          Пересчитать сейчас
        </b-button>
        <b-modal
          v-model="commandModal.show"
          title="Пересчет рейтинга"
          class="modal-warning"
          cancel-title="Назад"
          ok-only
        >
          <div
            v-if="commandModal.loading"
            class="d-flex justify-content-center mb-3"
          >
            Идет пересчет рейтинга
          </div>
          <div class="d-flex justify-content-center mb-3">
            <b>
              <p
                v-if="commandModal.success"
                class="text-success"
              >
                {{ commandModal.success }}
              </p>
              <p
                v-if="commandModal.error"
                class="text-danger"
              >
                {{ commandModal.error }}
              </p>
            </b>
            <b-spinner
              v-if="commandModal.loading"
              label="Loading..."
            />
          </div>
        </b-modal>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  customerRatingHistory,
  customerRatingIndexes,
  ratingPeriods,
  updateCustomerRatingIndex,
  updateCustomerRatingIndexOption,
} from '../../../services/api';
import moment from 'moment';
import notifications from '../../../components/mixins/notifications';
import LatenessRatingSetting from '@/views/Rating/Settings/LatenessRatingSetting';
import OptionalRatingSetting from '@/views/Rating/Settings/OptionalRatingSetting';
import SimpleRatingSetting from '@/views/Rating/Settings/SimpleRatingSetting';

const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_COMMAND_API_URL);

socket.onopen = () => {
  socket.send(JSON.stringify({
    event: 'authorize',
    data: {
      jwt: localStorage.token,
    },
  }));
};

export default {
  name: 'RatingSettings',
  components: {SimpleRatingSetting, OptionalRatingSetting, LatenessRatingSetting},
  mixins: [notifications],
  data: function() {
    return {
      indexes: [],
      history: [],
      periods: [
        {
          text: 'Неделя',
          value: 'monday last week',
        },
        {
          text: 'Две недели',
          value: 'monday -3 week',
        },
        {
          text: 'Три недели',
          value: 'monday -4 week',
        },
        {
          text: 'Месяц',
          value: 'first day of last month',
        },
      ],
      commandModal: {
        show: false,
        loading: false,
        error: '',
        success: '',
      },
    };
  },
  sockets: {
    connect: function() {
      console.log('socket connected');
    },
    customEmit: function(data) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
  },
  mounted() {
    this.getRatingIndexes();
    this.getRatingHistory();
  },
  methods: {
    async getRatingIndexes() {
      const response = await customerRatingIndexes(this.$store.state.user.ownerId);
      if (response && response.status === 200) {
        this.indexes = response.data.items.map((index) => {
          index.ratingPeriodIndexes.forEach((ratingPeriod) => {
            ratingPeriod.percentKoeff = ratingPeriod.koeff / 100;
            console.log(ratingPeriod.percentKoeff);
          });
          if (index.indexOptions) {
            index.indexOptions = index.indexOptions.sort((a, b) => {
              return a.min - b.min;
            });
          }
          return index;
        });
      }
    },
    async getRatingHistory() {
      const response = await customerRatingHistory(this.$store.state.user.ownerId);
      if (response && response.status === 200) {
        this.history = response.data.items;
      }
    },
    setNewKoeff(newValue) {
      this.percentKoeff = newValue;
    },
    async updateIndex(index) {
      if (index.periodStart === 'first day of last month') {
        index.periodEnd = 'first day of this month';
      } else {
        index.periodStop = 'monday this week';
      }
      let response = null;
      index.ratingPeriodIndexes.forEach((periodIndex) => {
        periodIndex.ratingIndex = {name: index.name};
        periodIndex.active = index.active;
        console.log(periodIndex.percentKoeff);
        periodIndex.koeff = (periodIndex.percentKoeff) * 100;
        response = updateCustomerRatingIndex(this.$store.state.user.ownerId, periodIndex.id, periodIndex);
        periodIndex.indexOptions.forEach((indexOption) => {
          updateCustomerRatingIndexOption(this.$store.state.user.ownerId, periodIndex.id, indexOption.id, indexOption);
        });
      });
      if (response && response.status === 200) {
        this.showSuccess('Настройки обновлены');
      }
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
    },
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    async recountAll() {
      this.commandModal.show = true;
      this.commandModal.loading = true;
      this.commandModal.success = '';
      this.commandModal.error = '';
      socket.send(JSON.stringify({
        event: 'command',
        data: {
          command: 'ratingRecountAll',
        },
      }));
      socket.onmessage = (event) => {
        console.log(event.data);
        console.log(this.commandModal);
        const message = JSON.parse(event.data);
        this.commandModal.loading = false;
        if (message.message === 'success') {
          this.commandModal.error = '';
          this.commandModal.success = 'Данные пересчитаны.';
        } else {
          this.commandModal.error = 'Произошла ошибка при выполнении комманды. '+ message.error;
          this.commandModal.success = '';
        }
      };
    },
  },
};
</script>
<style lang="scss">
    .history-rating {

        &__title {
        }

        &__desc {
        }

        &__list {
            list-style: inside none;
            overflow: auto;
            max-height: 444px;
            padding: 10px 5px;
            margin-bottom: 0;
            background-color: #2b2b2b;

            &-item {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                &-date {
                    color: #797979;
                }

                &-changes {
                    padding-left: 0;
                    list-style-position: inside;
                }
            }
        }
    }
</style>
