<template>
  <div>
    <h5>
      Таблица баллов
    </h5>
    <h6 class="mb-2">
      Сформируйте таблицу соотношения рейсов к баллам
    </h6>
    <table class="table table-bordered">
      <tr>
        <td>
          {{ index.name }}
        </td>
        <td
          v-for="indexOption in index.indexOptions"
          :key="indexOption.id"
        >
          <b-form inline>
            <label :for="'indexOptionMin'+indexOption.id"> от </label>
            <b-form-input
              :id="'indexOptionMin'+indexOption.id"
              v-model="indexOption.min"
              type="number"
              min="0"
              autocomplete="false"
              placeholder="От"
            />
          </b-form>
          <b-form inline>
            <label :for="'indexOptionMax'+indexOption.id"> до </label>
            <b-form-input
              :id="'indexOptionMax'+indexOption.id"
              v-model="indexOption.max"
              type="number"
              min="0"
              autocomplete="false"
              placeholder="До"
            />
          </b-form>
        </td>
      </tr>
      <tr>
        <td>
          Балл
        </td>
        <td
          v-for="indexOption in index.indexOptions"
          :key="indexOption.id"
        >
          <b-form-input v-model="indexOption.value" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'OptionalRatingSetting',
  props: ['index'],

};
</script>

<style scoped>

</style>
