<template>
  <div>
    <h6>
      Расчет происходит по формуле:
    </h6>
    {{ index.formula }}
  </div>
</template>

<script>
export default {
  name: 'SimpleRatingSetting',
  props: ['index'],

};
</script>

<style scoped>

</style>
